import * as React from 'react';
import { GrammarTopicTypeContent } from '@doltech/domains/grammar/lib/shared/utils/constants';
import { getFinalUrlGrammar } from '../../shared/utils';
import { formatDate } from '@doltech/utils/lib/date';
import { DAY_MONTH_NAME_FORMAT } from '@doltech/utils/lib/constants';
import { GrammarCardBlog } from './GrammarCardBlog';
import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';
import { s3ToCDN } from '@doltech/utils/lib/url';

interface TheoryCardBlogGrammarProps {
  data: any;
  ellipsisTitle?: any;
  isRecommend?: boolean;
  isShowImage?: boolean;
  isLanding?: boolean;
}

export const TheoryCardBlogGrammar = (props: TheoryCardBlogGrammarProps) => {
  const {
    data,
    ellipsisTitle,
    isRecommend = false,
    isShowImage = false,
    isLanding = false,
  } = props;
  const {
    lastModifiedAt,
    totalViews = 0,
    grammarTopic,
    status,
    url,
    unListed = false,
    dol,
  } = data || {};
  const { isLoggedIn } = useGetAuthInfo();
  const view = totalViews >= 1000 ? `${(totalViews / 1000).toFixed(1)}K` : totalViews;

  if (!isLoggedIn && unListed) {
    return null;
  }

  return (
    <GrammarCardBlog
      status={status}
      title={dol?.title}
      subRightText={`${view} lượt xem`}
      subLeftText={formatDate(lastModifiedAt, DAY_MONTH_NAME_FORMAT)}
      description={dol?.description}
      topicUrl={grammarTopic?.imageTopicUrl}
      topicName={GrammarTopicTypeContent[grammarTopic?.topicId]}
      detailUrl={getFinalUrlGrammar(url)}
      unListed={unListed}
      ellipsisTitle={ellipsisTitle}
      isRecommend={isRecommend}
      isLackOfUrl={!url}
      type="theory"
      imageUrl={s3ToCDN(dol?.fileInfo?.path)}
      imageAlt={dol?.fileInfo?.alt}
      isShowImage={isShowImage}
      isLanding={isLanding}
    />
  );
};
