import * as React from 'react';
import styled from 'styled-components';
import { ChipTopicHero } from './ChipTopicHero';
import { GrammarTopicTypeContent } from '@doltech/domains/grammar/lib/shared/utils/constants';
import { horizontalSpace, verticalSpace } from '@doltech/utils/lib/css-style';
import { slidingLeftToRight, slidingRightToLeft } from '@doltech/utils/lib/animations/slide';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';

const BaseLine = styled.div`
  display: flex;
  ${horizontalSpace(16)};

  &.line-2 {
    margin-left: -1904.56px;
  }
`;

const AnimateLine = styled.div`
  display: flex;
  ${horizontalSpace(16)};

  &.left-to-right {
    ${slidingLeftToRight};
  }

  &.right-to-left {
    ${slidingRightToLeft};
  }
`;

const Container = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  overflow: hidden;
  ${verticalSpace(16)};
  position: relative;
  margin: 16px;

  ${fromScreen(1366)} {
    width: 1080px;
    margin: 0 auto;
  }

  ${fromScreen(768)} {
    margin: 24px;
  }
`;

const GradientLayer = styled.div`
  position: absolute;
  top: -50px;
  width: 120px;
  height: 256px;
  border-radius: var(--border-radius-noneRadius, 0px);
  background: linear-gradient(90deg, #f1edfc 0%, rgba(241, 237, 252, 0) 100%);

  &.right {
    right: 0;
    background: linear-gradient(-90deg, #f1edfc 0%, rgba(241, 237, 252, 0) 100%);
  }
`;

interface GrammarAnimatedTopicGroupsProps {
  topics: any[];
}

export const GrammarAnimatedTopicGroups = (props: GrammarAnimatedTopicGroupsProps) => {
  const { topics } = props;
  const reorderTopics = [...topics.slice(6, 13), ...topics.slice(0, 6)];

  return (
    <Container>
      <BaseLine className="line-1">
        <AnimateLine className="right-to-left">
          {topics?.map((grammarTopic, index) => (
            <ChipTopicHero
              key={index}
              url={grammarTopic?.imageTopicUrl}
              text={GrammarTopicTypeContent[grammarTopic?.topicId]}
            />
          ))}
        </AnimateLine>
        <AnimateLine className="right-to-left">
          {topics?.map((grammarTopic, index) => (
            <ChipTopicHero
              key={index}
              url={grammarTopic?.imageTopicUrl}
              text={GrammarTopicTypeContent[grammarTopic?.topicId]}
            />
          ))}
        </AnimateLine>
      </BaseLine>
      <BaseLine className="line-2">
        <AnimateLine className="left-to-right">
          {topics?.slice()?.reverse()?.map((grammarTopic, index) => (
            <ChipTopicHero
              key={index}
              url={grammarTopic?.imageTopicUrl}
              text={GrammarTopicTypeContent[grammarTopic?.topicId]}
            />
          ))}
        </AnimateLine>
        <AnimateLine className="left-to-right">
          {topics?.slice()?.reverse()?.map((grammarTopic, index) => (
            <ChipTopicHero
              key={index}
              url={grammarTopic?.imageTopicUrl}
              text={GrammarTopicTypeContent[grammarTopic?.topicId]}
            />
          ))}
        </AnimateLine>
      </BaseLine>
      <BaseLine className="line-3">
        <AnimateLine className="right-to-left">
          {reorderTopics?.map((grammarTopic, index) => (
            <ChipTopicHero
              key={index}
              url={grammarTopic?.imageTopicUrl}
              text={GrammarTopicTypeContent[grammarTopic?.topicId]}
            />
          ))}
        </AnimateLine>
        <AnimateLine className="right-to-left">
          {reorderTopics?.map((grammarTopic, index) => (
            <ChipTopicHero
              key={index}
              url={grammarTopic?.imageTopicUrl}
              text={GrammarTopicTypeContent[grammarTopic?.topicId]}
            />
          ))}
        </AnimateLine>
      </BaseLine>
      <GradientLayer/>
      <GradientLayer className="right"/>
    </Container>
  );
};
