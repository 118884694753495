import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { verticalSpace } from '@doltech/utils/lib/css-style';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { GrammarSignUpButton } from '../../landing/components/header/GrammarSignUpButton';
import { GrammarAnimatedTopicGroups } from './GrammarAnimatedTopicGroups';
import { environment } from '@doltech/core/lib/environment/environment';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';

const Container = styled.section`
  background-color: ${colorsV2.purple10};
  padding: 120px 0;
  display: flex;
  justify-content: center;
`;

const TitleText = styled.h1`
  font-weight: 700;
  font-size: 48px;
  line-height: 57px;
  color: ${colorsV2.gray180};

  .underline {
    border-bottom: 4px solid ${colorsV2.primary100};
  }

  .highlight {
    color: ${colorsV2.primary100};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${verticalSpace(80)};
  width: 100%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${verticalSpace(32)};

  .text-wrapper {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    text-align: center;
    padding: 0 16px;
  }

  .grammar-sign-up-button {
    width: max-content;
  }

  ${fromScreen(768)} {
    .text-wrapper {
      width: 712px;
      padding: 0;
    }
  }
`;

interface SectionHomeHeroProps {
  topics: any[];
  onLogIn?: () => void;
  isLoggedIn?: boolean;
  title?: any;
  description?: string;
}

export const SectionHomeHero = (props: SectionHomeHeroProps) => {
  const { title, description, topics, isLoggedIn, onLogIn } = props;
  return (
    <Container>
      <Content>
        <TextWrapper>
          <div className="text-wrapper">
            <TitleText dangerouslySetInnerHTML={{ __html: title }}/>
            <ResponsiveTypography.Paragraph variant="regular/20-28" color="gray160">
              {description}
            </ResponsiveTypography.Paragraph>
          </div>
          {isLoggedIn ?
            <a href={environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}>
              <GrammarSignUpButton
                name="Tìm hiểu khóa học"
              />
            </a> :
            <GrammarSignUpButton
              name="Đăng ký miễn phí ngay"
              onClick={onLogIn}
            />
          }
        </TextWrapper>
        <GrammarAnimatedTopicGroups topics={topics}/>
      </Content>
    </Container>
  );
};
