import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { LineDivider } from '@doltech/ui/lib/figma/Divider/LineDivider';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1314px;
`;

export const GrammarSectionDivider = () => {
  return (
    <Container>
      <LineDivider color={colorsV2.gray20} space={0}/>
    </Container>
  );
};
