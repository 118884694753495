import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { horizontalSpace } from '@doltech/utils/lib/css-style';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';

const Container = styled.div`
  background-color: ${colorsV2.white100};
  padding: 10px 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  ${horizontalSpace(8)};
  white-space: nowrap;
`;

const MediaWrapper = styled.div`
  img {
    border-radius: 40px;
    width: 32px;
    height: 32px;
  }

  .empty-state {
    border-radius: 40px;
    width: 32px;
    height: 32px;
    background-color: ${colorsV2.gray10};
  }
`;

interface ChipTopicHeroProps {
  url?: string;
  text?: string;
}

export const ChipTopicHero = (props: ChipTopicHeroProps) => {
  const { url, text } = props;
  return (
    <Container>
      <MediaWrapper>
        {url ?
          <img src={url} alt={text}/> :
          <div className="empty-state"/>
        }
      </MediaWrapper>
      <ResponsiveTypography.Paragraph variant="medium/16-24" color="gray180">
        {text}
      </ResponsiveTypography.Paragraph>
    </Container>
  );
};
