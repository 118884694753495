import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { horizontalSpace, verticalSpace } from '@doltech/utils/lib/css-style';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { TextLink } from '@doltech/ui/lib/figma/TextLink/TextLink';
import {
  IconSystemV2ArrowChevronRightArrowRight,
} from '@doltech/icons/SystemV2/IconSystemV2ArrowChevronRightArrowRight';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';


const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 120px 0;
`;

const Main = styled.div`
  background-color: ${colorsV2.white100};
  display: grid;
  grid-template-columns: 3fr 7fr;
  ${horizontalSpace(40)};

  ${fromScreen(1128)} {
    width: 1080px;
    grid-template-columns: 350px auto;
  }
`;

const TitleWrapper = styled.div`
  position: sticky;
  top: 25%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  ${verticalSpace(24)};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${verticalSpace(16)};
`;

const BodyWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 32px;
`;

interface SectionHomeTheoryExerciseProps {
  data?: any;
  title?: string;
  description?: string;
  renderCardBlog?: (grammar: any) => React.ReactNode;
  viewAllUrl?: string;
}

export const SectionHomeTheoryAnimate = (props: SectionHomeTheoryExerciseProps) => {
  const { data, title, description, viewAllUrl, renderCardBlog } = props;
  return (
    <Container className="section-home-theory-exercise">
      <Main>
        <TitleWrapper>
          <ResponsiveTypography.Title level={2} variant="bold/32-40" color="gray180">
            <ResponsiveTypography.Text color="purple100">
              {title}
            </ResponsiveTypography.Text>
            {' '}Ngữ pháp
          </ResponsiveTypography.Title>
          <ContentWrapper>
            <ResponsiveTypography.Paragraph variant="regular/14-20" color="gray160">
              {description}
            </ResponsiveTypography.Paragraph>
            <a href={viewAllUrl}>
              <TextLink
                color="purple"
                size="medium"
                text="Xem tất cả"
                iconRight={<IconSystemV2ArrowChevronRightArrowRight />}
              />
            </a>
          </ContentWrapper>
        </TitleWrapper>
        <BodyWrapper>
          {data?.length > 0 && data?.map((grammar, index) => (
            <React.Fragment key={index}>
              {renderCardBlog(grammar)}
            </React.Fragment>
          ))}
        </BodyWrapper>
      </Main>
    </Container>
  );
};

