import * as React from 'react';
import styled from 'styled-components';
import { verticalSpace } from '@doltech/utils/lib/css-style';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { TextLink } from '@doltech/ui/lib/figma/TextLink/TextLink';
import {
  IconSystemV2ArrowChevronRightArrowRight,
} from '@doltech/icons/SystemV2/IconSystemV2ArrowChevronRightArrowRight';
import Link from 'next/link';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { useFromScreens } from '@doltech/ui/lib/hooks/useDeviceQuery.hook';
import { ButtonIconPrimarySize } from '@doltech/ui/lib/figma/Button/ButtonIconPrimarySize/ButtonIconPrimarySize';
import { ButtonIconPrimaryStyles } from '@doltech/ui/lib/figma/Button/ButtonIconPrimaryStyles/ButtonIconPrimaryStyles';
import { IconSystemV2ArrowArrowRightLine } from '@doltech/icons/SystemV2/IconSystemV2ArrowArrowRightLine';

const Container = styled.section`
  ${verticalSpace(16)}
  padding: 40px 16px;

  ${fromScreen(768)} {
    ${verticalSpace(32)}
    padding: 120px 24px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContentWrapper = styled.div`
  ${verticalSpace(12)};
`;

const ActionWrapper = styled(Link)`
  width: max-content;
  height: max-content;
  padding: 16px 0 36px;
`;

const BodyWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 24px;

  ${fromScreen(768)} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 32px;
  }
`;

interface SectionHomeTheoryExerciseProps {
  data?: any;
  title?: string;
  description?: string;
  renderCardBlog?: (grammar: any) => React.ReactNode;
  viewAllUrl?: string;
}

export const SectionHomeTheory = (props: SectionHomeTheoryExerciseProps) => {
  const { data, title, description, viewAllUrl, renderCardBlog } = props;
  const [, from768] = useFromScreens([0, 768]);

  return (
    <Container className="section-home-theory-exercise">
      <HeaderWrapper>
        <ContentWrapper>
          <ResponsiveTypography.Title level={2} variant="bold/32-40" color="gray180">
            <ResponsiveTypography.Text color="purple100">
              {title}
            </ResponsiveTypography.Text>
            {' '}Ngữ pháp
          </ResponsiveTypography.Title>
          <ResponsiveTypography.Paragraph variant="regular/14-20" color="gray160">
            {description}
          </ResponsiveTypography.Paragraph>
        </ContentWrapper>
        <ActionWrapper href={viewAllUrl}>
          {from768 ?
            <TextLink
              color="purple"
              size="medium"
              text="Xem tất cả"
              iconRight={<IconSystemV2ArrowChevronRightArrowRight/>}
            /> :
            <ButtonIconPrimaryStyles
              color="white"
              buttonStyle="outline"
              content={
                <ButtonIconPrimarySize size="x-large" icon={<IconSystemV2ArrowArrowRightLine/>}/>
              }
            />
          }
        </ActionWrapper>
      </HeaderWrapper>
      <BodyWrapper>
        {data?.length > 0 && data?.map((grammar, index) => (
          <React.Fragment key={index}>
            {renderCardBlog(grammar)}
          </React.Fragment>
        ))}
      </BodyWrapper>
    </Container>
  );
};

